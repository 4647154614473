@font-face {
  font-family: "Manrope";
  src: url(./assets/font/Manrope-Regular.ttf);
}
* {
  font-family: Manrope;
  letter-spacing: 0.8px;
}
.containers-body {
  background-color: #ffffff;
  max-width: 420px;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
}

.loading {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 420px;
  height: 100%;
  position: absolute;
  z-index: 9999999;
  .img-load {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spin {
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// @media only screen and (min-width: 720px) {
//   .containers-body {
//     height: 720px !important;
//     // margin-top: calc((100vh - 720px) / 2) !important;
//     position: relative;
//   }
// }
